import React, { Component } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
// import { connect } from "react-redux";
// import WHOLE from "../redux/statecore";
// // import ViewListIcon from '@mui/icons-material/ViewList';
// import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
// import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
// import NotFound from "./NotFound";
import './Home.css';
import MapList from './MapList';
import MapResources from './MapResources';
import Header from './Header';
// import AdmContentList from './AdmContentList';

// const mapStateToProps = (state) => ({
//   ...state,
// });
// const mapDispatchToProps = (dispatch) => ({
//   WHOLE: (payload) => dispatch(WHOLE(payload)),
// });

class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currentTabIndex: 0,
    }
  }

  componentDidMount() {
    // console.log(this.props.items)
    let initLan=(window.localStorage.getItem('i18nextLng'))
    window.localStorage.setItem("language", initLan);
  }

  handleTabChange = (e, tabIndex) => {
    // console.log(tabIndex);

    this.setState({ currentTabIndex: tabIndex });
  };

  render() {

    return (
      <>

        <div style={{
          backgroundColor: "yellow",
          // height: "150px",
          // zIndex: "999",
          // position: "relative",
        }}>
          <Header />
        </div>
        <div>
          <Tabs
            style={{
              backgroundColor: "teal",
              // height: "150px",
              zIndex: "999",
              position: "relative",
              top: "60px",
            }}
            // variant="fullWidth"
            centered
            value={this.state.currentTabIndex}
            onChange={this.handleTabChange}

            TabIndicatorProps={{
              sx: {
                backgroundColor: '#ffb620',
                height: 3
              },
            }}
          >
            <Tab
              style={{
                // backgroundColor:"#cccccc60",
                // borderRadius:10
              }}
              // icon={<SettingsSuggestIcon />}
              iconPosition="start"
              label={
                <Typography
                  sx={{
                    color: "primary",
                    fontWeight: "bold",
                  }}
                >
                  CITIZENS JOURNALISM
                </Typography>
              }
            />
            {/*<Tab
              // icon={<ManageAccountsIcon />}
              iconPosition="start"
              label={
                <Typography
                  sx={{
                    color: "primary",
                    fontWeight: "bold",
                  }}
                >
                  RESOURCES
                </Typography>
              }
              style={{
                // backgroundColor:"#224d91bf",
                // color: "#FFF!important"
              }} />*/}
          </Tabs>

          {/* TAB 1 Contents */}
          {this.state.currentTabIndex === 0 && (
            <Box sx={{ p: 3 }} >
              <MapList
              // items={this.props.items}
              // progress={this.props.progress}
              />
            </Box>
          )}

          {/* TAB 2 Contents */}
          {this.state.currentTabIndex === 1 && (
            <Box sx={{ p: 3 }}>
              <MapResources/>
            </Box>
          )}

        </div>
      </>

    )
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Home;